
import { defineComponent, computed } from "vue";
import { headerWidthFluid, headerLeft } from "@/core/helpers/config";
import { useMsal } from "@/composition-api/useMsal";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {},
  setup() {
    const { instance } = useMsal();

    const signOut = () => {
      instance.logoutRedirect();
      // useCleanStore();
    };
    return {
      signOut,
      headerWidthFluid,
      headerLeft,
      ambienteProd: computed(() =>
        process.env.VUE_APP_IS_BRANCH_PROD == "1" ? true : false
      ),
    };
  },
});
